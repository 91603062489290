import React from "react"
import kebabCase from "lodash.kebabcase"
import { Link } from "gatsby"

import ConcatWords from "../utils/ConcatWords"
import formatDate from "../utils/formatDate"

const renderList = ({ node }) => {
  return (
    <article className="blog-list__post" key={node.fields.slug}>
      <div className="blog-list__category">
        {node.frontmatter.category.map((cat, index, arr) => (
          <ConcatWords arrCount={arr.length} index={index} key={cat}>
            <Link to={`/blog/category/${kebabCase(cat)}`}>{cat}</Link>
          </ConcatWords>
        ))}
      </div>
      <Link className="hover:no-underline" to={node.fields.slug}>
        <h2 className="blog-list__post-title">{node.frontmatter.title}</h2>
        {node.frontmatter.subtitle && (
          <h3 className="blog-list__post-subtitle">
            {node.frontmatter.subtitle}
          </h3>
        )}
      </Link>

      <div className="blog-list__date">{formatDate(node.frontmatter.date)}</div>
    </article>
  )
}

export default renderList
