import React from "react"
import kebabCase from "lodash.kebabcase"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Pagination from "../components/pagination"
import renderList from "../components/renderList"
import { Link } from "gatsby"

const BlogCategory = ({ data, pageContext }) => {
  const { allMdx } = data
  const { currentPage, numPages, category, allCategories } = pageContext

  return (
    <Layout>
      <Seo title={category} />
      <h1 className="blog-list__mainHeading">{category}</h1>
      <div className="md:flex">
        <div className="md:w-3/4 md:pr-4 mb-16 md:mb-0">
          <div className="blog-list__posts">
            {allMdx.edges.map(renderList)}
            <Pagination
              currentPage={currentPage}
              numPages={numPages}
              contextPage={`category/${kebabCase(category)}`}
            />
          </div>
        </div>
        <div className="md:w-1/4 md:pl-4">
          <h3 className="mt-0">Categories:</h3>
          <div className="blog-categories">
            <Link to="/blog" className="blog-categories__cat">
              All Categories
            </Link>
            {allCategories.map((cat, index) => (
              <Link
                key={`cat-${index}`}
                to={`/blog/category/${kebabCase(cat)}`}
                className="blog-categories__cat"
              >
                {cat}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogCategory

export const query = graphql`
  query blogPostsListByCategory($category: String, $skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { category: { in: [$category] }, disabled: { ne: true } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date(formatString: "MMMM DD, YYYY")
            category
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 70) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
